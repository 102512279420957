import { PropsWithChildren } from 'react';

import { styled } from '@mui/material/styles';

import { useStyles } from './styles';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; /* lets scroll inside main. */
  background: white;
`;

export const Page = ({ children }: PropsWithChildren<unknown>) => {
  const styles = useStyles();
  // TODO: page tracking?
  return <Root className={styles['@global']}>{children}</Root>;
};
