import React from 'react';

export const LocationPin = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    name='locationPin'
    {...props}
  >
    <g clipPath='url(#a)' stroke='currentColor'>
      <path d='M13.3 5.794A5.297 5.297 0 0 0 8 .5a5.359 5.359 0 0 0-5.3 5.294c0 4.081 4.682 9.37 4.682 9.37A.88.88 0 0 0 8 15.5a.83.83 0 0 0 .619-.362S13.3 9.875 13.3 5.794z' />
      <path d='M8 3.37a2.136 2.136 0 1 1-2.165 2.136A2.155 2.155 0 0 1 8 3.369z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='currentColor' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
);
