import { PropsWithChildren } from 'react';

import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import { Scrim } from '../Scrim';

const VARIANT: Record<'standard' | 'modal', 'persistent' | 'temporary'> = {
  standard: 'persistent',
  modal: 'temporary'
};

export const getDirection = (anchor: Props['anchor']): Props['anchor'] =>
  anchor === 'right' ? 'left' : 'right';

type Props = {
  className?: string;
  anchor?: 'left' | 'right';
  hideBackdrop?: boolean;
  open?: boolean;
  size?: 'narrow' | 'wide';
  variant?: 'standard' | 'modal';
  onClose?: (reason: string) => void;
  onTransitionChange?: (state: 'open' | 'close') => void;
};

export const SideSheet = ({
  children,
  className,
  anchor = 'left',
  size = 'narrow',
  open,
  variant = 'modal',
  hideBackdrop,
  onClose,
  onTransitionChange
}: PropsWithChildren<Props>) => (
  <Root
    className={className}
    open={open}
    anchor={anchor}
    variant={VARIANT[variant]}
    size={size}
    hideBackdrop={hideBackdrop}
    onClose={onClose}
    SlideProps={{
      onEnter: () => onTransitionChange?.('open'),
      onExit: () => onTransitionChange?.('close'),
      easing: 'cubic-bezier(0.65, 0, 0.35, 1)',
      timeout: 600
    }}
    slots={{
      backdrop: Scrim
    }}
  >
    {children}
  </Root>
);

const Root = styled(Drawer)<{
  size?: 'narrow' | 'wide';
  variant?: 'persistent' | 'temporary';
}>`
  z-index: 1250;
  > .MuiPaper-root {
    width: ${({ size }) => (size === 'wide' ? '90%' : '768px')};
  }
`;
