import { AppStateProvider, notificationManager } from '@irc/carli-components';
import { Snackbar } from '@irc/shared-components-notification';
import { Analytics, Datadog } from '@irc/shared-features-analytics';
import { HeaderStateProvider } from '@irc/shared-features-header';
import { AuthClientProvider } from '@irc/shared-services-auth';
import { InlineManual } from '@irc/shared-services-inline-manual';
import { LaunchDarklyProvider } from '@irc/shared-services-launch-darkly';
import { ThemeProvider } from '@irc/shared-theme';
import { env } from '@irc/shared-utils-environment';
import { createQueryClient, QueryProvider } from '@irc/shared-utils-http';

import packageInfo from '../../package.json';
import { authClient } from '../auth';
import { theme } from '../theme';

import { LaunchDarklyIdentify } from './LaunchDarklyIdentify';
import { Router } from './Router';

const LD_FLAGS = Object.freeze({
  CarliDR: false,
  CarliPartsPortal: false,
  PartsCart: false,
  PriceCheck: false,
  CarFix: false,
  Carburetor: false
});

export const App = () => {
  const queryClient = createQueryClient();

  return (
    <HeaderStateProvider>
      <LaunchDarklyProvider
        clientId={env.NX_PUBLIC_LAUNCH_DARKLY_KEY}
        flags={LD_FLAGS}
      >
        <Analytics
          containerId='GTM-MFNPR8C4'
          authKey={env.NX_PUBLIC_GTM_AUTH}
          previewId={env.NX_PUBLIC_GTM_PREVIEW}
        />
        <AuthClientProvider client={authClient}>
          <Datadog
            trackUser
            enableSessionReplayRecording
            applicationId='b4bb635e-7a21-4558-9b59-1430497347db'
            clientToken='pubfa8e1022b50ca83ca01333ed6a74308b'
            service='irc-carli-ui'
            version={packageInfo.version}
          />
          <InlineManual apiKey={env.NX_PUBLIC_INLINE_MANUAL_KEY} />
          <ThemeProvider theme={theme}>
            <QueryProvider client={queryClient}>
              <AppStateProvider>
                <LaunchDarklyIdentify />
                <Router />
              </AppStateProvider>
            </QueryProvider>
            <Snackbar
              id={notificationManager.containerId}
              autoHide={2000}
              origin={{ vertical: 'bottom', horizontal: 'left' }}
            />
          </ThemeProvider>
        </AuthClientProvider>
      </LaunchDarklyProvider>
    </HeaderStateProvider>
  );
};
