import { Tile } from '@irc/carli-components';
import carliIcon from '@irc/shared-assets/logos/carli-icon.png';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { PageLayout } from '../../app/PageLayout';

enum SUPPORT_URL {
  CARLI_SUPPORT = 'https://carvana.service-now.com/scp/?id=sc_cat_item&table=sc_cat_item&sys_id=b31205288729e5109d1077b7cebb35e6',
  CARLI_ACCESS = 'https://carvana.service-now.com/scp/?id=sc_cat_item&table=sc_cat_item&sys_id=42fea2019741e910a79270700153afba'
}

const Root = styled(Box)`
  padding: 3rem 1rem;
  display: grid;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: center;
`;

const SupportTile = styled(Tile)`
  max-width: 550px;
  max-height: 125px;
  padding: 1rem;
  gap: 1rem;

  & .MuiListItemText-primary {
    text-align: left !important;
    font-size: 18px !important;
  }

  & .MuiListItemText-secondary {
    text-align: left !important;
  }

  & .MuiListItemText-multiline {
    width: 100% !important;
  }
`;

export const Support = () => {
  return (
    <PageLayout showFooter={false}>
      <Root>
        <SupportTile
          icon={carliIcon}
          to={SUPPORT_URL.CARLI_SUPPORT}
          label='Request Carli Support'
          description='Support for Carli Mobile Apps, Recon, Parts, and Repair Portal.'
        />
        <SupportTile
          icon={carliIcon}
          to={SUPPORT_URL.CARLI_ACCESS}
          label='Request Carli Access'
          description='Request access to Carli.'
        />
      </Root>
    </PageLayout>
  );
};
