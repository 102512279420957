import { RouteObject } from 'react-router-dom';

import {
  DEFAULT_AUTH_SIGNIN,
  DEFAULT_AUTH_SIGNIN_CALLBACK,
  DEFAULT_AUTH_SIGNOUT,
  DEFAULT_AUTH_SIGNOUT_CALLBACK,
  DEFAULT_AUTH_SILENT_RENEW_CALLBACK
} from '@irc/shared-services-auth';
import { createRouteMetadata, RouteMetadata } from '@irc/shared-utils-routing';

import { SilentCallbackHandler } from './components';
import { SignIn, SignInCallback, SignOutCallback } from './pages';

export const authRouteMetadata: Record<string, RouteMetadata> = {
  SignIn: createRouteMetadata(DEFAULT_AUTH_SIGNIN),
  SignInCallback: createRouteMetadata(DEFAULT_AUTH_SIGNIN_CALLBACK),
  SignOut: createRouteMetadata(DEFAULT_AUTH_SIGNOUT),
  SignOutCallback: createRouteMetadata(DEFAULT_AUTH_SIGNOUT_CALLBACK),
  SilentRenew: createRouteMetadata(DEFAULT_AUTH_SILENT_RENEW_CALLBACK)
};

interface AuthRoutesProps {
  applicationName: string;
  signInCallbackRedirectUri?: string;
  signInRedirectUri?: string;
  signOutCallbackRedirectUri?: string;
}
export const createAuthRoutes = ({
  applicationName,
  signInCallbackRedirectUri,
  signInRedirectUri,
  signOutCallbackRedirectUri
}: AuthRoutesProps): RouteObject[] => [
  {
    element: (
      <SignIn
        applicationName={applicationName}
        redirectUri={signInRedirectUri}
      />
    ),
    path: authRouteMetadata['SignIn'].pattern
  },
  {
    element: <SignInCallback redirectUri={signInCallbackRedirectUri} />,
    path: authRouteMetadata['SignInCallback'].pattern
  },
  {
    element: <SignOutCallback redirectUri={signOutCallbackRedirectUri} />,
    path: authRouteMetadata['SignOutCallback'].pattern
  },
  {
    element: <SilentCallbackHandler />,
    path: authRouteMetadata['SilentRenew'].pattern
  }
];
