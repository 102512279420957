import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import { Typography } from '@irc/shared-components-typography';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

const Root = styled('div')`
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
`;

const FileInput = styled(InputBase)`
  display: none;
`;

const Label = styled('label')`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
`;

const DragTarget = styled('div')`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const Button = styled(ButtonBase)`
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  background-color: transparent;

  &:hover {
    text-decoration-line: underline;
  }
`;

export type DropZoneProps = {
  accept?: string;
  multiple?: boolean;
  onChange?: (files: FileList) => void;
};

export const DropZone = forwardRef<HTMLInputElement, DropZoneProps>(
  (props, ref) => {
    const { accept, multiple, onChange } = props;
    const [dragActive, setDragActive] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleDrag = function (evt: React.DragEvent<HTMLElement>) {
      evt.preventDefault();
      evt.stopPropagation();

      if (evt.type === 'dragenter' || evt.type === 'dragover') {
        setDragActive(true);
      } else if (evt.type === 'dragleave') {
        setDragActive(false);
      }
    };

    // triggers when file is dropped
    const handleDrop = function (evt: React.DragEvent<HTMLElement>) {
      const files = evt.dataTransfer.files;

      evt.preventDefault();
      evt.stopPropagation();
      setDragActive(false);

      if (files && files[0]) {
        onChange?.(files);
      }
    };

    // triggers when file is selected with click
    const handleChange = function (evt: React.ChangeEvent<HTMLInputElement>) {
      const files = evt.target.files;

      evt.preventDefault();

      if (files && files[0]) {
        onChange?.(files);
      }
    };

    const handleUploadClick = () => {
      inputRef.current?.click();
    };

    return (
      <Root onDragEnter={handleDrag}>
        <FileInput
          inputRef={inputRef}
          type='file'
          id='input-file-upload'
          onChange={handleChange}
          inputProps={{
            accept,
            multiple
          }}
        />
        <Label htmlFor='input-file-upload'>
          <div>
            <Typography
              variant='labelM'
              text='Drag and drop your file here or'
            />
            <Button onClick={handleUploadClick}>Upload a file</Button>
          </div>
        </Label>
        {dragActive ? (
          <DragTarget
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        ) : null}
      </Root>
    );
  }
);
