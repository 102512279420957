import React, { useEffect, useRef } from 'react';

import {
  AppLayout,
  Desktop,
  Mobile,
  useAppLayout,
  useAppState
} from '@irc/carli-components';
import { RequireAuthentication } from '@irc/shared-features-auth';
import { useHeaderState } from '@irc/shared-features-header';
import { useFlags } from '@irc/shared-services-launch-darkly';
import Box from '@mui/material/Box';

import { PageLayout } from '../../app/PageLayout';

export const Home = () => {
  const [{ selectedLocation }] = useHeaderState();

  const { isMobile } = useAppLayout();
  const [, setAppState] = useAppState();
  const LDFlags = useFlags();

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const onChangeView = (e: React.MouseEvent) => {
    e.preventDefault();
    const nextLayout = isMobile ? AppLayout.Desktop : AppLayout.Mobile;
    setAppState((prevState) => ({ ...prevState, layout: nextLayout }));
  };

  return (
    <RequireAuthentication>
      <PageLayout
        footerLinks={[
          {
            id: 'layout-link',
            onClick: onChangeView,
            label: isMobile ? 'View Desktop' : 'View Mobile'
          }
        ]}
      >
        <Box pt={5} pb={5}>
          {isMobile ? (
            <Mobile flags={LDFlags} selectedLocation={selectedLocation ?? ''} />
          ) : (
            <Desktop
              flags={LDFlags}
              selectedLocation={selectedLocation ?? ''}
            />
          )}
        </Box>
      </PageLayout>
    </RequireAuthentication>
  );
};
