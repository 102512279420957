import { ComponentProps, useRef } from 'react';

import {
  SearchField as SharedSearchField,
  SearchFieldResult
} from '@irc/shared-components-search-field';
import { TYPOGRAPHY } from '@irc/shared-theme';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { InputProps } from '@mui/material/Input';
import { styled } from '@mui/material/styles';

import { useHeaderState } from '../../context';

export type SearchFieldProps = ComponentProps<typeof SharedSearchField> & {
  handleVehicleScan: (result: SearchFieldResult) => void;
  inputMode?: 'none' | 'text';
  showKeyboardIcon?: boolean;
  onKeyboardIconClick?: () => void;
};

export const SearchField = ({
  placeholder = 'Search VIN or stock number',
  handleVehicleScan,
  inputMask = /[^a-z0-9]+/gi,
  inputMode = 'text',
  showKeyboardIcon = false,
  onKeyboardIconClick,
  ...props
}: SearchFieldProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [{ vehicleSearchTerm }, dispatch] = useHeaderState();

  return (
    <Box display='flex' alignItems='center'>
      <StyledDiv style={{ flexGrow: 1 }}>
        <SharedSearchField
          inputRef={ref}
          value={vehicleSearchTerm || ''}
          variant='standard'
          InputProps={
            { disableUnderline: true, fullWidth: true } as Partial<InputProps>
          }
          inputProps={{ inputMode }}
          onSearch={handleVehicleScan}
          inputMask={inputMask}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({
              vehicleSearchTerm: (e.currentTarget as HTMLInputElement).value
            })
          }
          onCancel={() => dispatch({ vehicleSearchTerm: '' })}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
            e.currentTarget.select()
          }
          placeholder={placeholder}
          {...props}
        />
      </StyledDiv>
      {showKeyboardIcon && (
        <IconButton
          sx={{
            padding: 0,
            paddingLeft: '6px'
          }}
          color='primary'
          onClick={onKeyboardIconClick}
        >
          <KeyboardIcon fontSize='medium' />
        </IconButton>
      )}
    </Box>
  );
};

const StyledDiv = styled('div')`
  & .MuiFormControl-root {
    width: 100%;
  }
  & .MuiInputBase-root {
    background: #f3f7f9;
    border-radius: 1.75px;
    color: black;
    height: 40px;
    ${{ ...TYPOGRAPHY.bodyL }}
    ${(props) => props.theme.breakpoints.down('lg')} {
      height: 36px;
      ${{ ...TYPOGRAPHY.bodyM }}
    }
    ${(props) => props.theme.breakpoints.down('md')} {
      height: 31px;
      ${{ ...TYPOGRAPHY.bodyS }}
    }
  }
  & .Mui-disabled {
    background-color: #fafafa;
    opacity: 0.5;
    & button {
      &:hover {
        cursor: default;
      }
    }
  }
  & button {
    &:hover {
      background-color: transparent;
    }
    ${(props) => props.theme.breakpoints.down('sm')} {
      padding: 5px;
    }
  }
`;
