import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  root: {
    height: ({ fullScreen }: { fullScreen: boolean }) =>
      fullScreen ? '100%' : 'unset',
    width: ({ fullScreen }: { fullScreen: boolean }) =>
      fullScreen ? '100%' : 'unset'
  }
}));
