import React from 'react';
import debounce from 'lodash/debounce';

import InputAdornment from '@mui/material/InputAdornment';
import MuiTextField from '@mui/material/TextField';

import { useInputMask } from '../../hooks/useInputMask';

export type TextFieldInput = HTMLInputElement & HTMLTextAreaElement;

export type EnhancedProps = {
  notifyOnBlur?: boolean;
  notifyOnEnter?: boolean;
  inputMask?: RegExp;
  wait?: number;
};

export type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  EnhancedProps & {
    // common mui props
    label?: React.ReactNode;
    multiline?: boolean;
    helperText?: React.ReactNode;
    error?: boolean;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;

    // textarea props
    maxRows?: string | number;
    minRows?: string | number;
  };

export const TextField = React.forwardRef<TextFieldInput, TextFieldProps>(
  (props, ref) => {
    const {
      className,
      autoFocus,
      value: valueProp,
      required,
      disabled,
      readOnly,
      name,
      rows,
      onChange,
      onKeyUp,
      onKeyDown,
      onBlur,
      onFocus,

      // common mui props
      label,
      multiline,
      helperText,
      error,
      endAdornment,
      startAdornment,

      // textarea props
      maxRows,
      minRows,

      // enhanced props
      notifyOnBlur,
      notifyOnEnter,
      inputMask,
      wait,
      ...rest
    } = props;

    const [inputMaskRef, setMaskedValue] = useInputMask(ref, { inputMask });
    const [value, setValue] = React.useState<unknown>('');

    const changeRef = React.useRef<typeof onChange | undefined>(onChange);
    React.useEffect(() => {
      changeRef.current = onChange;
    });

    React.useEffect(() => {
      setValue(valueProp);
      setMaskedValue((valueProp as string) ?? '');
    }, [valueProp, setMaskedValue]);

    const debounceHandler = React.useMemo(() => {
      return debounce((evt: React.ChangeEvent<TextFieldInput>) => {
        changeRef.current?.(evt);
      }, wait);
    }, [wait]);

    const handleKeyUp = (evt: React.KeyboardEvent<TextFieldInput>) => {
      if ((evt.key === 'Enter' || evt.code === 'Enter') && notifyOnEnter) {
        debounceHandler.flush();
      }

      onKeyUp?.(evt);
    };

    const handleInputBlur = (evt: React.FocusEvent<TextFieldInput>) => {
      if (notifyOnBlur) {
        debounceHandler.flush();
      }

      onBlur?.(evt);
    };

    const handleInputChange = (evt: React.ChangeEvent<TextFieldInput>) => {
      const maskedValue = setMaskedValue(evt.target.value);
      setValue(maskedValue);
      debounceHandler(evt);
    };

    React.useEffect(
      () => () => {
        debounceHandler.cancel();
      },
      [debounceHandler]
    );

    return (
      <MuiTextField
        inputRef={inputMaskRef}
        variant='outlined'
        className={className}
        value={value}
        label={label}
        multiline={multiline}
        helperText={helperText}
        required={required}
        error={error}
        disabled={disabled}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onFocus={onFocus}
        name={name}
        rows={rows}
        maxRows={maxRows}
        minRows={minRows}
        InputProps={{
          autoComplete: 'off',
          autoFocus,
          readOnly,
          endAdornment: endAdornment ? (
            <InputAdornment position='end'>{endAdornment}</InputAdornment>
          ) : undefined,
          startAdornment: startAdornment ? (
            <InputAdornment position='start'>{startAdornment}</InputAdornment>
          ) : undefined,
          onKeyUp: handleKeyUp,
          onKeyDown
        }}
        inputProps={{
          autoComplete: 'off',
          ...rest
        }}
      />
    );
  }
);
