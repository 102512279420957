import React, { Fragment } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiDivider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

export interface FooterLink {
  id: string;
  href?: string;
  onClick?: (e: React.MouseEvent) => void;
  label: string;
}

type Props = {
  links?: FooterLink[];
};

const Root = styled('div')`
  text-align: right;
`;

const NavBar = styled(Box)`
  background-color: ${({ theme }) => theme.palette.text.primary};
  align-items: center;
  height: 56px;
  display: flex;
  justify-content: center;
`;

const Divider = styled(MuiDivider)`
  height: 50%;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})<{ $active?: boolean }>`
  text-transform: none;
  color: ${({ theme, $active }) =>
    $active ? '#00AED9' : theme.palette.common.white};
  &:hover {
    color: #00aed9;
  }
`;

export const Footer = ({ links = [] }: Props) => {
  return (
    <Root className='footer'>
      <NavBar>
        {links.map(({ id, href, onClick, label }, index) => (
          <Fragment key={id}>
            {index !== 0 && <Divider orientation='vertical' variant='middle' />}
            <StyledButton
              {...{ component: Link, underline: 'none' }}
              {...(href ? { href, target: '_blank' } : { onClick })}
              data-qa={id}
            >
              {label}
            </StyledButton>
          </Fragment>
        ))}
      </NavBar>
    </Root>
  );
};
