import { PropsWithChildren } from 'react';

import {
  contactSupportLink,
  Footer,
  FooterLink
} from '@irc/shared-components-footer';
import { Content, Main, Page } from '@irc/shared-components-page';
import { styled } from '@mui/material/styles';

import { Header } from './Header';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'fixed',
  backgroundColor: '#fff',
  height: '100%',
  '.footer': {
    zIndex: theme.zIndex.drawer + 1,
    bottom: 0,
    position: 'fixed',
    width: '100%'
  }
}));

const StyledContent = styled(Content)`
  padding-bottom: 4rem;
`;

export const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;

  & > header {
    position: relative;
  }

  .MuiInputBase-root {
    width: 100%;
    max-width: 600px;
  }

  .MuiFormControl-root {
    align-items: center;
  }
`;

interface PageLayoutProps extends PropsWithChildren {
  footerLinks?: FooterLink[];
  showFooter?: boolean;
}
export const PageLayout = ({
  children,
  footerLinks = [],
  showFooter = true
}: PageLayoutProps) => {
  return (
    <Root>
      <Page>
        <HeaderContainer>
          <Header />
        </HeaderContainer>
        <StyledContent>
          <Main>{children}</Main>
        </StyledContent>
        {showFooter && <Footer links={[contactSupportLink, ...footerLinks]} />}
      </Page>
    </Root>
  );
};
