import { forwardRef, ReactNode } from 'react';

import { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

import { PrimaryButton } from '../PrimaryButton';

type Props = ButtonProps & {
  isLoading?: boolean;
  progressComponent?: ReactNode | (() => ReactNode);
};

const LoadingSpinner = styled(CircularProgress)`
  padding: 0;
  height: 28px;
  line-height: 28px;
`;

export const LoadingButton = forwardRef<HTMLButtonElement, Props>(
  ({ isLoading, progressComponent, children, onClick, ...props }, ref) => {
    const ProgressComponent =
      typeof progressComponent === 'function'
        ? progressComponent()
        : progressComponent || (
            <LoadingSpinner color='inherit' thickness={2} size={25} />
          );

    const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
      if (!isLoading && onClick) onClick(evt);
    };

    return (
      <PrimaryButton ref={ref} onClick={handleClick} {...props}>
        {isLoading ? ProgressComponent : children}
      </PrimaryButton>
    );
  }
);
