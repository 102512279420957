import { styled } from '@mui/material/styles';

type Props = {
  alt?: string;
  logoMark?: string;
  wordMark?: string;
};

const Root = styled('picture')`
  & img {
    vertical-align: top;
  }
`;

export const ResponsiveLogo = ({
  alt,
  logoMark = '',
  wordMark = ''
}: Props) => (
  <Root>
    <source media='(max-width: 699px)' srcSet={logoMark} />
    <source media='(min-width: 700px)' srcSet={wordMark} />
    <img src={wordMark} alt={alt} />
  </Root>
);
