// Reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const getIsTouchscreenDevice = () => {
  let hasTouchScreen = false;
  const userAgent = navigator.userAgent;
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else {
    const mQ = matchMedia?.('(pointer:coarse)');
    if (mQ?.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true;
    } else {
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgent);
    }
  }

  return hasTouchScreen;
};

export enum ScanDetectionGATags {
  VEHICLE_SEARCH = 'vehicle_search',
  SCAN_LOCATION_NOT_AVAILABLE = 'scan_location_not_available'
}
