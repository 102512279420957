import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));
