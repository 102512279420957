import { forwardRef } from 'react';

import Fade from '@mui/material/Fade';
import { alpha, styled } from '@mui/material/styles';

export const Root = styled('div')`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => alpha(theme.palette.text.primary, 0.5)};
`;

export type ScrimProps = React.HTMLAttributes<HTMLDivElement> & {
  open?: boolean;
};

export const Scrim = forwardRef<HTMLDivElement, ScrimProps>((props, ref) => {
  const { children, open, ...rest } = props;

  return (
    <Fade in={open}>
      <Root ref={ref} {...rest}>
        {children}
      </Root>
    </Fade>
  );
});
