import { Suggestion } from '@irc/shared-components-form';
import { Location } from '@irc/shared-services-location';

export const formatLabel = (location: Location) => {
  const locationCode = location.shortName ? `(${location.shortName})` : '';
  return [location.name, locationCode].join(' ').trim();
};
export const formatDescription = ({ address }: Location) => {
  const locale = [address?.city, address?.state].filter(Boolean);
  return [address?.line1, locale.join(', '), address?.zip5].join(' ').trim();
};

export const mapLocationToSuggestion = (location: Location): Suggestion => {
  return {
    id: location.shortName,
    label: formatLabel(location),
    description: formatDescription(location)
  };
};
