import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const hoverStyle = {
  '&:hover': {
    backgroundColor: 'white',
    cursor: 'default'
  }
};

const iconStyle = (palette: Theme['palette']) => ({
  color: palette.info.light,
  padding: 0
});

export default makeStyles(({ palette, breakpoints }) => ({
  menuItem: hoverStyle,
  listItem: {
    padding: 0,
    alignItems: 'center',
    ...hoverStyle
  },
  menuItemHover: {
    '&:hover': {
      backgroundColor: palette.grey[50],
      cursor: 'pointer'
    }
  },
  listItemHover: {
    padding: 0,
    alignItems: 'center',
    '&:hover': {
      backgroundColor: palette.grey[50]
    }
  },
  icon: iconStyle(palette),
  mainIcon: {
    ...iconStyle(palette),
    width: 'inherit'
  },
  paper: {
    width: '100%',
    [breakpoints.up('sm')]: {
      width: 250
    },
    borderRadius: '8px',
    marginTop: 15,
    right: 5,
    boxShadow:
      '0px 8px 28px rgba(24, 53, 88, 0.04), 0px 4px 8px rgba(24, 53, 88, 0.07), 0px -2px 8px rgba(24, 53, 88, 0.03)',
    '&::before': {
      position: 'absolute',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: `10px solid ${palette.background.paper}`
    }
  },
  textEllipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  textEllipsisPrimary: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  signOut: {
    textAlign: 'center',
    color: palette.error.main
  },
  action: {
    cursor: 'pointer'
  },
  divider: {
    borderColor: palette.background.default,
    height: '3px'
  }
}));
