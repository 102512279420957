import { useMemo, useRef, useState } from 'react';
import { useLatest } from 'react-use';

import { ReactComponent as Pin } from '@irc/shared-assets/illustrations/pin-104px.svg';
import { PrimaryButton } from '@irc/shared-components-button';
import {
  AutocompleteField,
  Suggestion,
  SuggestionType
} from '@irc/shared-components-form';
import { Modal } from '@irc/shared-components-modal';
import { Typography } from '@irc/shared-components-typography';
import {
  type Location,
  useParentLocations
} from '@irc/shared-services-location';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

import { Header } from './components/Header';
import { LocationButton } from './components/LocationButton';
import { useIsFullScreen } from './hooks/useIsFullScreen';
import { mapLocationToSuggestion } from './utils';

type Props = {
  value?: string;
  locationTypes?: string[];
  onCancel?: () => void;
  onChange?: (locationCode: string) => void;
  locationFilterFn?: (locations: Location[]) => Location[];
};

export const LocationPicker = ({
  value,
  locationTypes,
  onCancel,
  onChange,
  locationFilterFn
}: Props) => {
  const isFullScreen = useIsFullScreen();

  const headerRef = useRef<HTMLDivElement>(null);
  const confirmRef = useRef<ButtonBaseActions | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [stagedLocationCode, setStagedLocationCode] = useState<
    string | undefined
  >();

  const { data: locations = [] } = useParentLocations(locationTypes);

  const latestLocationFilterFn = useLatest(locationFilterFn);
  const suggestions = useMemo(() => {
    const filterFn = latestLocationFilterFn.current;
    const filteredLocations = filterFn ? filterFn(locations) : locations;
    return filteredLocations?.map<Suggestion>(mapLocationToSuggestion);
  }, [locations, latestLocationFilterFn]);

  const currentLocation = useMemo(() => {
    return suggestions.find(({ id }) => id === value);
  }, [suggestions, value]);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleConfirmation = () => {
    if (stagedLocationCode) {
      onChange?.(stagedLocationCode);
      setStagedLocationCode(undefined);
    }

    setIsOpen(false);
  };

  const handleClose = () => {
    onCancel?.();
    if (stagedLocationCode) {
      setStagedLocationCode(undefined);
    }
    setIsOpen(false);
  };

  const handleChange = (suggestion: SuggestionType) => {
    if (!suggestion) return;
    const locationCode = (suggestion as Suggestion).id;

    if (isFullScreen) {
      onChange?.(locationCode);
      setIsOpen(false);
    } else {
      setStagedLocationCode(locationCode);
      confirmRef.current?.focusVisible();
    }
  };

  return (
    <>
      <LocationButton locationCode={value} onClick={handleClick} />
      <StyledModal open={isOpen} maxWidth='sm' onClose={handleClose}>
        <Header ref={headerRef} onCancel={handleClose}>
          {isFullScreen ? (
            <SearchField
              autoFocus
              autoSelect
              label='Location'
              anchorRef={headerRef}
              suggestions={suggestions}
              onChange={handleChange}
              value={currentLocation}
            />
          ) : null}
        </Header>
        <Content>
          <Showcase isFullScreen={isFullScreen}>
            <Pin />
            <Typography variant='bodyL' text='Select a new location.' />
          </Showcase>
          {!isFullScreen ? (
            <SearchField
              autoFocus
              autoSelect
              label='Location'
              suggestions={suggestions}
              onChange={handleChange}
              value={currentLocation}
            />
          ) : null}
        </Content>
        {!isFullScreen ? (
          <Footer>
            <PrimaryButton
              action={confirmRef}
              fullWidth
              size='large'
              onClick={handleConfirmation}
            >
              Confirm
            </PrimaryButton>
          </Footer>
        ) : null}
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  .MuiPaper-root {
    width: 100%;
  }
`;

const SearchField = styled(AutocompleteField)`
  width: 100%;
`;

const Content = styled(DialogContent)`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2, 3)};
`;

const Showcase = styled('div')<{ isFullScreen?: boolean }>`
  padding-bottom: ${({ isFullScreen, theme }) =>
    !isFullScreen ? theme.spacing(1) : 'unset'};
`;

const Footer = styled(DialogActions)`
  padding: ${({ theme }) => theme.spacing(1, 3, 3)};
  justify-content: center;
`;
