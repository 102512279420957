import { Location } from '@irc/shared-services-location';

export const ACCEPTABLE_OL_LOCATIONS = Object.freeze([
  'FRD-OL',
  'HFD-OL',
  'POR-MH-OL',
  'HOU-MH-OL',
  'NYC-MH-OL',
  'LSV-MH-OL',
  'SAC-OL'
]);

export const ACCEPTABLE_MH_LOCATIONS = Object.freeze([
  'ADT-MH',
  'GMW-MH',
  'NNJ-MH',
  'POR-MH',
  'POS-MH',
  'SWA-MH',
  'SYR-MH',
  'WVO-MH',
  'HST-MH'
]);

export const ACCEPTABLE_CARLI_LOCATIONS = Object.freeze([
  'BES-IC',
  'BLM-IC',
  'BMA-IC',
  'BNY-IC',
  'CHE-IC',
  'CLE-IC',
  'CON-IC',
  'DEL-IC',
  'ELY-IC',
  'HEA-IC',
  'HNS-IC',
  'HPA-IC',
  'HST-IC',
  'IND-IC',
  'KCM-IC',
  'LCA-IC',
  'LNV-IC',
  'NNJ-IC',
  'NSH-IC',
  'OKC-IC',
  'PAZ-IC',
  'PHX-IC',
  'POS-IC',
  'ROC-IC',
  'SAA-IC',
  'TOO-IC',
  'UNI-IC',
  'TRE-IC',
  'WME-IC',
  'WND-IC'
]);

export const ACCEPTABLE_HB_LOCATIONS = Object.freeze(['BLH-HB']);

export const filterLocations = (locations: Location[]) => {
  if (!locations) return [];
  return locations.filter(({ shortName }) => {
    if (shortName) {
      return (
        ACCEPTABLE_CARLI_LOCATIONS.includes(shortName) ||
        ACCEPTABLE_OL_LOCATIONS.includes(shortName) ||
        ACCEPTABLE_MH_LOCATIONS.includes(shortName) ||
        ACCEPTABLE_HB_LOCATIONS.includes(shortName)
      );
    }

    return false;
  });
};

export const ACCEPTABLE_LOCATION_TYPES = ['IC', 'OL', 'MH', 'HB'];
export function isValidLocation(location: Location | null): boolean {
  const locationTypes = (location && location.locationTypes) || [];
  return locationTypes.some(
    ({ shortCode }) => ACCEPTABLE_LOCATION_TYPES.indexOf(shortCode) !== -1
  );
}
