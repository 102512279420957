import CarburetorLogo from '@irc/shared-assets/logos/carburetor-204px.svg';
import CarCheckLogo from '@irc/shared-assets/logos/carcheck-204px.svg';
import CardiacLogo from '@irc/shared-assets/logos/cardiac-204px.svg';
import CarFixLogo from '@irc/shared-assets/logos/carfix-204px.svg';
import CarInspectLogo from '@irc/shared-assets/logos/carinspect-204px.svg';
import CarPartsLogo from '@irc/shared-assets/logos/carparts-204px.svg';
import CarStockLogo from '@irc/shared-assets/logos/carstock-204px.svg';
import { env } from '@irc/shared-utils-environment';
import { styled } from '@mui/material/styles';

import { Tile } from '../Tile';

const Root = styled('div')`
  display: grid;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: center;
`;

type Props = {
  selectedLocation: string;
  flags?: Record<string, boolean>;
};

export const Mobile = ({ selectedLocation, flags = {} }: Props) => {
  const { CarliPartsPortal, CarFix, Carburetor } = flags;

  const carCheckLink = env.NX_PUBLIC_CARCHECK_REDIRECT;
  const carStockLink = env.NX_PUBLIC_CARSTOCK_REDIRECT;
  const carPartsLink = env.NX_PUBLIC_CARPARTS_REDIRECT;
  const cardiacPartsLink = env.NX_PUBLIC_CARDIAC_REDIRECT;
  const carInspectLink = env.NX_PUBLIC_CAR_INSPECT_REDIRECT;
  const carFixLink = env.NX_PUBLIC_CAR_FIX_REDIRECT;
  const carburetorLink = env.NX_PUBLIC_CARBURETOR_REDIRECT;

  const getLink = (link?: string) =>
    selectedLocation ? `${link}?location=${selectedLocation}` : link;

  return (
    <Root>
      <Tile icon={CarStockLogo} to={getLink(carStockLink)} />
      <Tile icon={CarCheckLogo} to={getLink(carCheckLink)} />
      {CarliPartsPortal && (
        <Tile icon={CarPartsLogo} to={getLink(carPartsLink)} />
      )}
      <Tile icon={CardiacLogo} to={getLink(cardiacPartsLink)} />
      {Carburetor && (
        <Tile icon={CarburetorLogo} to={getLink(carburetorLink)} />
      )}
      <Tile icon={CarInspectLogo} to={getLink(carInspectLink)} />
      {CarFix && <Tile icon={CarFixLogo} to={getLink(carFixLink)} />}
    </Root>
  );
};
