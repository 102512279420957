export interface HeaderState {
  vehicleSearchTerm?: string;
  selectedLocation?: string;
}

export const reducer = (state: HeaderState, action: Partial<HeaderState>) => ({
  ...state,
  ...action
});

export const INITIAL_STATE = Object.freeze({
  vehicleSearchTerm: '',
  selectedLocation: ''
});
