import React from 'react';

import { useContextWithError } from '@irc/shared-utils-react';
import { useStorageItem } from '@irc/shared-utils-storage';

export enum AppLayout {
  Unset,
  Desktop,
  Mobile
}

export interface AppState {
  layout: AppLayout;
}

export type AppStorageSchema = Record<string, AppState>;

type AppStateContext = ReturnType<typeof useStorageItem<AppStorageSchema>>;

export const AppStateContext = React.createContext<AppStateContext | undefined>(
  undefined
);

const APP_CONTEXT_ERROR =
  "Couldn't locate app state. Is your component inside the AppStateProvider?";

export const useAppState = () =>
  useContextWithError(AppStateContext, APP_CONTEXT_ERROR);
