import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { appStorage, notificationManager } from '@irc/carli-components';
import { ReactComponent as HeyCarliLogo } from '@irc/shared-assets/logos/hey-carli-logo.svg';
import { pushToDataLayer } from '@irc/shared-features-analytics';
import {
  Header as SharedHeader,
  useHeaderState
} from '@irc/shared-features-header';
import { useAuthState } from '@irc/shared-services-auth';
import { usePostLocationToVPS } from '@irc/shared-services-vps';
import theme from '@irc/shared-theme';
import { env } from '@irc/shared-utils-environment';
import {
  getIsTouchscreenDevice,
  InputSource,
  type ScanDetectionChangeEventType,
  ScanDetectionGATags,
  useScanDetection
} from '@irc/shared-utils-scan-detection';
import { useStorageItem } from '@irc/shared-utils-storage';
import { useMediaQuery } from '@mui/material';

import { ACCEPTABLE_LOCATION_TYPES, filterLocations } from '../utils';

enum InputMode {
  None = 'none',
  Text = 'text'
}

const homePath = '/home';

export const Header = () => {
  const navigate = useNavigate();
  const { user, status } = useAuthState();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [{ selectedLocation }, headerDispatch] = useHeaderState();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const isAuthenticated = status === 'authenticated';

  const isHome = window.location.pathname === homePath;

  const [inputMode, setInputMode] = useState<InputMode>(InputMode.None);

  const [persistedLocationCode, setPersistedLocationCode] = useStorageItem(
    'locationCode',
    undefined,
    appStorage
  );

  useEffect(() => {
    if (selectedLocation) setPersistedLocationCode(selectedLocation);
  }, [selectedLocation, setPersistedLocationCode]);

  useEffect(() => {
    if (
      isAuthenticated &&
      isHome &&
      persistedLocationCode &&
      !selectedLocation
    ) {
      headerDispatch({ selectedLocation: persistedLocationCode as string });
    }
  }, [
    headerDispatch,
    isAuthenticated,
    isHome,
    persistedLocationCode,
    selectedLocation
  ]);

  const onLocationNotAvailable = useCallback(
    (searchTerm: string) => {
      pushToDataLayer(ScanDetectionGATags.SCAN_LOCATION_NOT_AVAILABLE, {
        user: user?.profile?.email,
        searchTerm
      });
    },
    [user?.profile?.email]
  );

  const { sendLocationToVPS } = usePostLocationToVPS({
    notificationManager,
    source: 'CarliSearch',
    onLocationNotAvailable
  });

  const onScanDetectionChange = useCallback(
    ({ value, inputSource }: ScanDetectionChangeEventType) => {
      window.open(`${env.NX_PUBLIC_CARLI_SEARCH_REDIRECT}?searchTerm=${value}`);
      searchInputRef.current?.blur();
      headerDispatch({ vehicleSearchTerm: '' });

      pushToDataLayer(ScanDetectionGATags.VEHICLE_SEARCH, {
        user: user?.profile?.email,
        location: selectedLocation,
        inputSource,
        searchTerm: value
      });

      if (inputMode === InputMode.None && inputSource === InputSource.Scanned) {
        sendLocationToVPS(value);
      }
      setInputMode(InputMode.None);
    },
    [
      headerDispatch,
      user?.profile?.email,
      selectedLocation,
      inputMode,
      sendLocationToVPS
    ]
  );

  const { handleChange, handlePaste, onInput } = useScanDetection(
    onScanDetectionChange
  );

  useEffectOnce(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus({ preventScroll: true });
    }
  });

  const onShowKeyboardIconClick = () => {
    setInputMode(InputMode.Text);
    searchInputRef.current?.focus();
  };

  const handleLogoClick = () => {
    searchInputRef.current?.focus();
    navigate(homePath);
  };

  return (
    <SharedHeader
      searchInputRef={searchInputRef}
      handleVehicleScan={handleChange}
      onPaste={handlePaste}
      Logo={<HeyCarliLogo />}
      handleLogoClick={handleLogoClick}
      autoComplete='off'
      onInput={onInput}
      hasLocationPicker={isAuthenticated && isHome}
      hideUserMenu={!isAuthenticated}
      showKeyboardIcon={getIsTouchscreenDevice()}
      inputMode={inputMode}
      showSearch={isAuthenticated && isHome}
      onKeyboardIconClick={onShowKeyboardIconClick}
      isSearchInline={!isMobile}
      locationFilterFn={filterLocations}
      locationTypes={ACCEPTABLE_LOCATION_TYPES}
    />
  );
};
