import { MutableRefObject } from 'react';

import Popper, { PopperProps } from '@mui/material/Popper';

import { setAttributes, setStyles } from './utils';

export const PopperWrapper =
  (anchorRef: MutableRefObject<HTMLElement | null>) =>
  ({ style, ...props }: PopperProps) =>
    (
      <Popper
        {...props}
        anchorEl={anchorRef?.current}
        style={{ ...style, width: anchorRef?.current?.offsetWidth || 'auto' }}
        modifiers={[
          {
            name: 'flip',
            options: {
              enabled: false
            }
          },
          {
            name: 'applyStyle',
            options: {
              enabled: true
            },
            fn: (data: any) => {
              const el = data.instance.popper;
              setStyles(el, {
                position: 'absolute',
                top: data.offsets.reference.height,
                bottom: 0,
                left: 0,
                right: 0
              });
              setAttributes(el, data.attributes);
              return data;
            }
          }
        ]}
      />
    );
