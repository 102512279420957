import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  '& .MuiFormControl-root': {
    width: '100%',
    margin: theme.spacing(1, 0)
  }
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightBold,
  margin: 'auto',
  textAlign: 'center'
}));

export const DialogTitleContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 10fr 1fr',
  textTransform: 'uppercase',
  boxShadow: theme.shadows[1]
}));
