import React from 'react';

type InputMaskOptions = {
  inputMask?: RegExp;
};

// TODO: this is more a util hook
export const useInputMask = <
  T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement
>(
  inputRef?: React.Ref<T>,
  options?: InputMaskOptions
) => {
  const ref = React.useRef<T>(null);
  React.useImperativeHandle(inputRef, () => ref.current as T);

  const setMaskedValue = React.useCallback(
    (value: string) => {
      let nextValue = value;
      if (options?.inputMask) nextValue = value.replace(options.inputMask, '');

      if (ref.current) {
        ref.current.value = nextValue;
      }

      return nextValue;
    },
    [options?.inputMask]
  );

  return [ref, setMaskedValue] as const;
};
