import LocationPin from '@mui/icons-material/LocationOnOutlined';
import ListItemText from '@mui/material/ListItemText';

import { StyledDivider, StyledListItem } from './styles';

type Props = {
  locationCode?: string;
};

export function LocationLabel({ locationCode }: Props) {
  if (!locationCode) return null;
  return (
    <>
      <StyledListItem disableGutters>
        <LocationPin />
        <ListItemText primary={locationCode || 'Unknown'} />
      </StyledListItem>
      <StyledDivider flexItem orientation='vertical' />
    </>
  );
}
