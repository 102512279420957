import { useHeaderState } from '@irc/shared-features-header';
import { LaunchDarklyIdentifySessionUser } from '@irc/shared-services-launch-darkly';

export const LaunchDarklyIdentify = () => {
  const [{ selectedLocation }] = useHeaderState();

  return (
    <LaunchDarklyIdentifySessionUser
      custom={{
        location: selectedLocation ?? '',
        sourceApplication: 'Carli'
      }}
    />
  );
};
