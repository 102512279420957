import { AxiosError } from 'axios';

export interface ResponseError {
  type: string;
  message: string;
}

export const getErrorMessage = <T extends { errors: any[] }>(
  e: AxiosError<T> | Error
): string =>
  ('response' in e && e.response?.data?.errors?.[0]?.message) || e.message;

export const getErrorMessages = <T extends { errors: any[] }>(
  e: AxiosError<T> | Error
): ResponseError[] => {
  if ('response' in e && e.response?.data?.errors)
    return e.response?.data?.errors;
  if (e.message) return [{ type: e.name, message: e.message }];
  return [];
};

function isErrorStatus<T>(status: number, error?: AxiosError<T> | Error) {
  return error && 'response' in error && error.response?.status === status;
}
export function is404<T>(error?: AxiosError<T> | Error) {
  return isErrorStatus(404, error);
}
export function is409<T>(error?: AxiosError<T> | Error) {
  return isErrorStatus(409, error);
}
export function isNetworkError<T>(error?: AxiosError<T> | Error) {
  return error && error.message === 'Network Error';
}
