import ListItemText from '@mui/material/ListItemText';

export type Suggestion = {
  id: string;
  label?: string;
  description?: string;
};

type Props = Omit<Suggestion, 'id'>;

export const AutocompleteItem = ({ label, description }: Props) => {
  return <ListItemText primary={label} secondary={description} />;
};
