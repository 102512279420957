import { compile, parse, PathFunction, Token } from 'path-to-regexp';
import { Params } from 'react-router-dom';

export type RouteMetadata<T extends Params = never> = {
  pattern: string;
  tokens: Token[];
  toPath: PathFunction<Partial<T>>;
};

export function createRouteMetadata<T extends Params>(
  pattern: string
): RouteMetadata<T> {
  const tokens = parse(pattern);
  const toPath = compile<Partial<T>>(pattern, { encode: encodeURIComponent });

  return {
    pattern,
    tokens,
    toPath
  };
}
