import { Button, Icon, IconButton } from '@mui/material';

import { useStyles } from './styles';

import '@irc/shared-assets/fonts/icons/icons.css';

type Props = {
  locationCode?: string;
  onClick: () => void;
};

export const LocationButton = ({ locationCode, onClick }: Props) => {
  const styles = useStyles();

  return !locationCode ? (
    <IconButton
      aria-label='locationPin'
      aria-controls='long-menu'
      aria-haspopup='true'
      onClick={onClick}
    >
      <Icon component='i' className='icon-pin' />
    </IconButton>
  ) : (
    <Button
      id='locationButton'
      className={styles.root}
      variant='text'
      color='primary'
      size='large'
      onClick={onClick}
    >
      {locationCode.split('-')[0]}
    </Button>
  );
};
