import { useCallback, useEffect, useState } from 'react';

import { useAuthState } from '@irc/shared-services-auth';
import { useGetVehicleDetail, VehicleDetail } from '@irc/shared-services-vtram';
import { ServiceClientResponse } from '@irc/shared-utils-http';
import { useGeolocation } from '@irc/shared-utils-location';

import { useIngestPosition } from '../endpoints/position/position';
import type { CreatePositionRequest, PositionSource } from '../models';

type Props = {
  // cannot use NotificationManager from shared-components because of circular dependency
  notificationManager?: Record<
    'info' | 'success' | 'warning' | 'error',
    (message: string, opts?: object) => void
  >;
  source: PositionSource;
  additionalProps?: CreatePositionRequest;
  onLocationNotAvailable?: (searchTerm: string) => void;
  onVpsSuccess?: () => void;
};

export const usePostLocationToVPS = ({
  notificationManager,
  source,
  additionalProps,
  onLocationNotAvailable,
  onVpsSuccess
}: Props) => {
  const { user } = useAuthState();
  const { location, getCurrentPosition } = useGeolocation();
  const longitude = location?.coords.longitude ?? 0;
  const latitude = location?.coords.latitude ?? 0;

  const [searchTerm, setSearchTerm] = useState<string>('');

  const vehicleSelector = (data: ServiceClientResponse<VehicleDetail[]>) =>
    data.responseBody[0];

  const { remove } = useGetVehicleDetail(searchTerm, undefined, {
    query: {
      queryKey: ['vtram-history'],
      select: vehicleSelector,
      enabled: !!searchTerm,
      onSuccess: (data) => {
        if (data) {
          postLocationToVPS(data.vin ?? '');
        }
      }
    }
  });

  const { mutate, status } = useIngestPosition({
    mutation: {
      mutationKey: ['ingestPosition'],
      retry: 2,
      onError: () =>
        notificationManager?.error('Failed to send location to VPS', {
          autoClose: false
        }),
      onSuccess: () => {
        notificationManager?.success('Location sent to VPS');
        setSearchTerm('');
        remove();
        onVpsSuccess?.();
      }
    }
  });

  const postLocationToVPS = useCallback(
    (vin: string) => {
      if (!longitude || !latitude) {
        onLocationNotAvailable?.(searchTerm);
        notificationManager?.info(
          'Location Not Available, Please Enable Location Services',
          { autoClose: false }
        );
        return;
      }
      return mutate({
        data: {
          vin,
          source,
          longitude,
          latitude,
          userName: user?.profile?.email ?? '',
          reportedDateTime: new Date().toISOString(),
          ...additionalProps
        }
      });
    },
    [
      additionalProps,
      latitude,
      longitude,
      mutate,
      notificationManager,
      onLocationNotAvailable,
      searchTerm,
      source,
      user?.profile?.email
    ]
  );

  useEffect(() => {
    getCurrentPosition();
  }, [getCurrentPosition]);

  const sendLocationToVPS = useCallback((searchTerm: string) => {
    setSearchTerm(searchTerm);
  }, []);

  return { sendLocationToVPS, status };
};
