import { env } from '@irc/shared-utils-environment';

const Urls = {
  SUPPORT: env.NX_PUBLIC_CARLI_SUPPORT_REDIRECT ?? '',
  HEY_CARLI: env.NX_PUBLIC_CARLI_REDIRECT ?? ''
};

export const heyCarliLink = {
  id: 'footer-hey-carli',
  href: Urls.HEY_CARLI,
  label: 'HeyCarli'
};

export const contactSupportLink = {
  id: 'footer-support',
  href: Urls.SUPPORT,
  label: 'Support'
};
