import { RedirectClient as AuthClient, Scope } from '@irc/shared-services-auth';
import { env } from '@irc/shared-utils-environment';

const requiredScopes = [
  Scope.LocationRead,
  Scope.VTRAM,
  Scope.VehiclePositionWrite
];

export const authClient = new AuthClient({
  authority: `${env.NX_PUBLIC_DOMAIN}/auth/identity`,
  clientId: 'cvna-irc-carli-ui',
  scope: requiredScopes,
  silentRedirectUri: `${window.location.origin}/silent-renew.html`
});
