import React from 'react';

import { Typography } from '@irc/shared-components-typography';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

type TileButtonProps = {
  Logo: React.ElementType;
  name: string;
  selected?: boolean;
  onClick: (name: string) => void;
};

const StyledIcon = styled('div')`
  display: flex;
  align-items: center;
`;

const StyledMenuItem = styled(MenuItem)<{ component?: React.ElementType }>`
  &.MuiMenuItem-root {
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }
  padding-right: ${({ theme }) => theme.spacing(4)};
  box-shadow: 0 3px 5px rgba(24, 53, 88, 0.03);
  align-items: center;
`;

export const TileButton = ({
  Logo,
  name,
  selected,
  onClick
}: TileButtonProps) => {
  return (
    <StyledMenuItem
      key={name}
      component='div'
      onClick={() => onClick(name)}
      selected={selected}
    >
      <StyledIcon>
        <Logo />
      </StyledIcon>
      <Typography variant='headerM' text={name} />
    </StyledMenuItem>
  );
};
