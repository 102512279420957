import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

export const StyledRightContainer = styled('div')`
  align-items: center;
  display: flex;
`;

export const RightContainer = styled('section')`
  align-items: center;
`;

export const CenterContainer = styled('section')`
  flex: 1;
`;

export const LeftContainer = styled('section')<{
  $showSearch?: boolean;
  $inline?: boolean;
}>`
  display: flex;
  justify-content: flex-start;

  ${({ $showSearch, $inline }) =>
    $showSearch && !$inline ? 'flex: 1 0 100%;' : ''};

  ${(props) => props.theme.breakpoints.up('xs')} {
    gap: ${(props) => props.theme.spacing(1)};
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    gap: ${(props) => props.theme.spacing(1.25)};
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    gap: ${(props) => props.theme.spacing(1.5)};
  }
`;

export const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 3px 5px rgba(24, 53, 88, 0.03);
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

export const StyledToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => !prop.toString().startsWith('$')
})<{ $showSearch?: boolean }>(
  ({ theme, $showSearch }) => `
  ${
    $showSearch
      ? `flex-wrap: wrap;`
      : `
        display: flex;
        justify-content: space-between;
    `
  }
  padding: ${theme.spacing(1, 1.5, 0.5, 1.5)} !important;
  ${theme.breakpoints.up('xs')} {
    gap: ${theme.spacing(0.5)};
  }
  ${theme.breakpoints.up('md')} {
    gap: ${theme.spacing(0.75)};
  }
  ${theme.breakpoints.up('lg')} {
    gap: ${theme.spacing(1)};
  }
`
);

export const LogoButton = styled('button')`
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  line-height: 0;
  & > svg {
    width: auto;
    @media (min-width: 0px) {
      height: 25px;
    }
    @media (min-width: 768px) {
      height: 30px;
    }
    @media (min-width: 1024px) {
      height: 35px;
    }
  }
`;

export const StyledDiv = styled('div')`
  span > i,
  button > span {
    @media (min-width: 0px) {
      font-size: 0.9em;
    }
    @media (min-width: 768px) {
      font-size: 1em;
    }
    @media (min-width: 1024px) {
      font-size: 1.1em;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  @media (min-width: 0px) {
    height: 25px;
  }
  @media (min-width: 768px) {
    height: 30px;
  }
  @media (min-width: 1024px) {
    height: 35px;
  }
  display: grid;
  align-self: center;
  color: ${({ theme }) => theme.palette.divider};
`;
