import ApprovalWorkbenchLogo from '@irc/shared-assets/logos/approval-workbench-square-204px.svg';
import CarburetorLogo from '@irc/shared-assets/logos/carburetor-square-204px.svg';
import CardiacLogo from '@irc/shared-assets/logos/cardiac-square-204px.svg';
import CarFixLogo from '@irc/shared-assets/logos/carfix-square-204px.svg';
import PartsInvestigatorLogo from '@irc/shared-assets/logos/parts-investigator-square-204px.svg';
import PartsPortalLogo from '@irc/shared-assets/logos/parts-portal-square-204px.svg';
import PartsReturnLogo from '@irc/shared-assets/logos/parts-return-square-204px.svg';
import PartsCartLogo from '@irc/shared-assets/logos/partscart-square-204px.svg';
import PriceCheckLogo from '@irc/shared-assets/logos/price-check-square-204px.svg';
import ReconPortalLogo from '@irc/shared-assets/logos/recon-portal-square-204px.svg';
import RepairPortalLogo from '@irc/shared-assets/logos/repair-portal-square-208px.svg';
import { env } from '@irc/shared-utils-environment';
import { styled } from '@mui/material/styles';

import { Tile } from '../Tile';

const Root = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: center;
  max-width: 932px;
  margin: 0 auto;
`;

const DesktopTile = styled(Tile)`
  &.MuiPaper-root {
    width: 100%;
    max-width: 320px;
    height: 100px;
  }
`;

type Props = {
  selectedLocation: string;
  flags?: Record<string, boolean>;
};

export const Desktop = ({ flags = {}, selectedLocation }: Props) => {
  const { CarliDR, PartsCart, PriceCheck, CarFix, Carburetor } = flags;

  const getEnvRedirectUrl = (envVar: string) => {
    return (
      (CarliDR === true ? env[`${envVar}_DR`] : null) ?? env[envVar]
    )?.trim();
  };

  const reconRedirect = getEnvRedirectUrl('NX_PUBLIC_RECON_REDIRECT');
  const partsRedirect = getEnvRedirectUrl('NX_PUBLIC_PARTS_REDIRECT');
  const repairRedirect = getEnvRedirectUrl('NX_PUBLIC_REPAIR_REDIRECT');
  const approvalWorkbenchRedirect = getEnvRedirectUrl(
    'NX_PUBLIC_APPROVAL_WORKBENCH_REDIRECT'
  );
  const partsInvestigatorRedirect = getEnvRedirectUrl(
    'NX_PUBLIC_PARTS_INVESTIGATOR_REDIRECT'
  );
  const cardiacRedirect = getEnvRedirectUrl('NX_PUBLIC_CARDIAC_REDIRECT');
  const partsReturnRedirect = getEnvRedirectUrl(
    'NX_PUBLIC_PARTS_RETURN_REDIRECT'
  );
  const partsCartRedirect = `${getEnvRedirectUrl(
    'NX_PUBLIC_PARTS_CART_REDIRECT'
  )}?location=${selectedLocation ?? ''}`;

  const projectXRedirect = `${getEnvRedirectUrl(
    'NX_PUBLIC_PROJECTX_REDIRECT'
  )}?location=${selectedLocation ?? ''}`;

  const carFixRedirect = getEnvRedirectUrl('NX_PUBLIC_CAR_FIX_REDIRECT');
  const carburetorRedirect = `${getEnvRedirectUrl(
    'NX_PUBLIC_CARBURETOR_REDIRECT'
  )}?location=${selectedLocation ?? ''}`;

  return (
    <Root>
      <DesktopTile
        icon={ReconPortalLogo}
        to={reconRedirect}
        title='Recon Portal'
      />
      <DesktopTile
        icon={PartsPortalLogo}
        to={partsRedirect}
        title='Parts Portal'
      />
      <DesktopTile
        icon={RepairPortalLogo}
        to={repairRedirect}
        title='Repair Portal'
      />
      <DesktopTile
        icon={ApprovalWorkbenchLogo}
        to={approvalWorkbenchRedirect}
        title='Approval Workbench'
      />
      <DesktopTile
        icon={PartsInvestigatorLogo}
        to={partsInvestigatorRedirect}
        title='Parts Investigator'
      />
      <DesktopTile
        icon={PartsReturnLogo}
        to={partsReturnRedirect}
        title='Parts Return'
      />
      <DesktopTile icon={CardiacLogo} to={cardiacRedirect} title='Cardiac' />
      {PartsCart && (
        <DesktopTile
          icon={PartsCartLogo}
          to={partsCartRedirect}
          title='Parts Cart'
        />
      )}
      {PriceCheck && (
        <DesktopTile
          icon={PriceCheckLogo}
          to={projectXRedirect}
          title='Price Check'
        />
      )}
      {CarFix && (
        <DesktopTile icon={CarFixLogo} to={carFixRedirect} title='Car Fix' />
      )}
      {Carburetor && (
        <DesktopTile
          icon={CarburetorLogo}
          to={carburetorRedirect}
          title='Carburetor'
        />
      )}
    </Root>
  );
};
