import isNumber from 'lodash/isNumber';

export const NUMERIC_STYLES = [
  'width',
  'height',
  'top',
  'right',
  'bottom',
  'left'
];

export const setAttributes = (
  element: HTMLElement,
  attributes: Record<string, any>
) => {
  Object.keys(attributes).forEach(function (prop) {
    const value = attributes[prop];
    if (value !== false) {
      element.setAttribute(prop, attributes[prop]);
    } else {
      element.removeAttribute(prop);
    }
  });
};

export const setStyles = (
  element: HTMLElement,
  styles: Record<string, any>
) => {
  Object.keys(styles).forEach((prop) => {
    let unit = '';
    // add unit if the value is numeric and is one of the following
    if (NUMERIC_STYLES.includes(prop) && isNumber(styles[prop])) {
      unit = 'px';
    }
    element.style[prop as any] = `${styles[prop]}${unit}`;
  });
};
