import { forwardRef, PropsWithChildren } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';

import { useStyles } from './styles';

type Props = {
  onCancel?: () => void;
};

export const Header = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, onCancel }, ref) => {
    const styles = useStyles();
    const handleCancel = () => onCancel?.();

    return (
      <AppBar position='relative' color='transparent' elevation={0} ref={ref}>
        <Toolbar variant='dense' className={styles.toolbar}>
          <Typography
            variant='h4'
            color='inherit'
            className={styles.title}
            align='center'
          >
            Enter Location
          </Typography>
          <IconButton edge='end' onClick={handleCancel} color='inherit'>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {children ? (
          <Toolbar variant='dense' className={styles.toolbar}>
            {children}
          </Toolbar>
        ) : null}
      </AppBar>
    );
  }
);
