import {
  Dialog,
  DialogProps,
  Fade,
  useMediaQuery,
  useTheme
} from '@mui/material';

import { SlideTransition } from '../SlideTransition';

import useStyles from './styles';

export const Modal = ({
  children,
  maxWidth,
  ...dialogProps
}: React.PropsWithChildren<DialogProps>) => {
  const { breakpoints } = useTheme();
  const isFullScreen = !useMediaQuery(breakpoints.up('sm'));
  const styles = useStyles({ fullScreen: isFullScreen });

  return (
    <Dialog
      fullWidth
      fullScreen={isFullScreen}
      maxWidth={isFullScreen ? undefined : maxWidth || 'md'}
      TransitionComponent={isFullScreen ? SlideTransition : Fade}
      classes={{
        paper: styles.root
      }}
      {...dialogProps}
    >
      {children}
    </Dialog>
  );
};
