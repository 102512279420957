import { forwardRef, PropsWithChildren, ReactNode } from 'react';
import { noop } from 'lodash';

import { SearchFieldResult } from '@irc/shared-components-search-field';
import {
  LocationLabel,
  LocationPicker
} from '@irc/shared-features-location-picker';
import { useAuthClient, useAuthState } from '@irc/shared-services-auth';
import type { Location } from '@irc/shared-services-location';

import { SearchField } from './components/SearchField';
import { UserMenu } from './components/UserMenu';
import { useHeaderState } from './context';
import {
  CenterContainer,
  LeftContainer,
  LogoButton,
  RightContainer,
  StyledAppBar,
  StyledDiv,
  StyledDivider,
  StyledRightContainer,
  StyledToolbar
} from './styles';

export type HeaderProps = {
  Logo: ReactNode;
  showSearch?: boolean;
  Menu?: ReactNode;
  handleLocationChange?: (loc: string) => void;
  handleVehicleScan?: (result: SearchFieldResult) => void;
  locationTypes?: string[];
  handleLogoClick?: () => void;
  disableSearch?: boolean;
  hasLocationPicker?: boolean;
  hideUserMenu?: boolean;
  searchInputRef?: React.RefObject<HTMLInputElement>;
  inputMode?: 'none' | 'text';
  showKeyboardIcon?: boolean;
  onKeyboardIconClick?: () => void;
  isSearchInline?: boolean;
  locationFilterFn?: (locations: Location[]) => Location[];
} & Pick<
  React.ComponentProps<typeof SearchField>,
  'inputMask' | 'placeholder' | 'onPaste' | 'autoComplete' | 'onInput'
>;

export const Header = forwardRef<HTMLDivElement, HeaderProps>(
  (
    {
      Logo,
      Menu,
      handleLocationChange,
      handleVehicleScan = noop,
      locationTypes,
      handleLogoClick,
      placeholder,
      showSearch = true,
      inputMask,
      disableSearch,
      hasLocationPicker = true,
      searchInputRef,
      onPaste,
      autoComplete,
      onInput,
      onKeyboardIconClick,
      showKeyboardIcon,
      inputMode,
      isSearchInline = false,
      locationFilterFn,
      hideUserMenu = false
    }: PropsWithChildren<HeaderProps>,
    ref
  ) => {
    const { user } = useAuthState();
    const session = useAuthClient();
    const [{ selectedLocation }, dispatch] = useHeaderState();

    const clearSearchInput = () => {
      dispatch({
        vehicleSearchTerm: ''
      });
    };

    const handleLocationSelect = (locationString: string) => {
      handleLocationChange?.(locationString);
      dispatch({ selectedLocation: locationString });
      clearSearchInput();
    };

    const handleHomeClick = () => {
      handleLogoClick?.();
      clearSearchInput();
    };

    const handleSignOut = () => {
      session.signOut();
    };

    const locationPicker = hasLocationPicker ? (
      <LocationPicker
        locationFilterFn={locationFilterFn}
        value={selectedLocation}
        locationTypes={locationTypes}
        onChange={handleLocationSelect}
      />
    ) : (
      <LocationLabel locationCode={selectedLocation} />
    );

    const userMenu = !hideUserMenu && (
      <UserMenu
        title={user?.profile?.name || ''}
        subtitle={user?.profile?.title as string}
        location={selectedLocation}
        handleSignOut={handleSignOut}
      />
    );

    return (
      <StyledAppBar ref={ref} color='transparent' elevation={0}>
        <StyledToolbar $showSearch={showSearch}>
          <LeftContainer $showSearch={showSearch} $inline={isSearchInline}>
            {Menu}
            {Menu && <StyledDivider orientation='vertical' flexItem />}
            <LogoButton title='Logo' onClick={handleHomeClick}>
              {Logo}
            </LogoButton>
          </LeftContainer>
          {!showSearch ? (
            <StyledRightContainer>
              {locationPicker}
              {userMenu}
            </StyledRightContainer>
          ) : null}
          {showSearch ? (
            <>
              <CenterContainer>
                <SearchField
                  inputRef={searchInputRef}
                  placeholder={placeholder}
                  handleVehicleScan={handleVehicleScan}
                  disabled={disableSearch}
                  inputMask={inputMask}
                  onPaste={onPaste}
                  autoComplete={autoComplete}
                  onInput={onInput}
                  inputMode={inputMode}
                  onKeyboardIconClick={onKeyboardIconClick}
                  showKeyboardIcon={showKeyboardIcon}
                />
              </CenterContainer>
              <RightContainer>
                <StyledDiv>{locationPicker}</StyledDiv>
              </RightContainer>
              <RightContainer>{userMenu}</RightContainer>
            </>
          ) : null}
        </StyledToolbar>
      </StyledAppBar>
    );
  }
);
