import React from 'react';

import FileUpload from '@mui/icons-material/Folder';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';

import { TextField, TextFieldProps } from '../TextField';

export type UploadFieldProps = Omit<
  TextFieldProps,
  'rows' | 'maxRows' | 'minRows' | 'onChange'
> & {
  onChange?: (files?: File) => void;
};

const StyledTextField = styled(TextField)`
  & input,
  & > * {
    cursor: pointer;
  }
`;

export const UploadField = React.forwardRef<HTMLInputElement, UploadFieldProps>(
  (props, ref) => {
    const { accept, onChange, disabled, ...rest } = props;

    const inputRef = React.useRef<HTMLInputElement>(null);
    React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleInputClick = ({ target }: React.MouseEvent<HTMLInputElement>) =>
      ((target as HTMLInputElement).value = '');

    const handleInputChange = ({
      target
    }: React.ChangeEvent<HTMLInputElement>) => {
      // TODO: should we return entire filelist?
      onChange?.(target.files?.[0]);
    };

    const triggerInputClick = () => inputRef.current?.click();

    // TODO: split props between textfield and file input
    return (
      <>
        <StyledTextField
          {...rest}
          readOnly
          onClick={triggerInputClick}
          disabled={disabled}
          endAdornment={
            <ButtonBase
              component='label'
              disabled={disabled}
              onClick={triggerInputClick}
            >
              <FileUpload color='action' titleAccess='upload' />
            </ButtonBase>
          }
        />
        <input
          ref={inputRef}
          hidden
          type='file'
          accept={accept}
          onChange={handleInputChange}
          onClick={handleInputClick}
        />
      </>
    );
  }
);
