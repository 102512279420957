import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1, 1.75),
  backgroundColor: theme.palette.grey[300],
  height: '30px'
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.text.primary
}));
