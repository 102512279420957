import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  toolbar: {
    flexWrap: 'wrap',
    flexDirection: 'row-reverse'
  },
  title: {
    position: 'absolute',
    left: 0,
    right: 0
  }
}));
